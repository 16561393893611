<template>
  <div>
    <div v-if="!hideEnquiry" class="h-16 md:h-20 lg:h-28"></div>
    <div
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-main.jpg')"
    >
      <div class="container">
        <div class="py-28 md:py-40 lg:py-48">
          <h1
            class="text-3xl font-bold text-center text-white lg:text-5xl md:text-4xl"
          >
            Looking for <span class="text-brown-4">Vinyl Flooring</span> and
            <span class="text-brown-4">SPC Flooring?</span>
          </h1>
          <p
            class="pt-4 font-medium leading-tight text-center text-white lg:text-xl md:text-lg"
          >
            We Provide Free Professional Consultation & Installation
          </p>

          <div v-if="!hideEnquiry" class="pt-5 text-white md:space-x-2 md:justify-center md:flex">
            <p
              class="py-2 mx-auto mb-2 text-center border-2 border-white md:mb-0 md:mx-0 w-44"
            >
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                >Get Quotation Now</a
              >
            </p>
            <p
              class="py-2 mx-auto text-center border-2 border-white md:mx-0 w-44"
            >
              <a href="https://wa.me/60123877518">Whatsapp Us</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>

<template>
  <div class="home">
    <WaBtn />
    <!-- header  -->
    <div class="fixed z-10 w-full py-2 bg-white shadow-md">
      <div class="container">
        <div
          class="flex items-center justify-between"
        >
          <img src="/images/main-logo.png" alt="" class="w-32 lg:w-36" />
          <div
            class="duration-200 ease-in-out transform text-brown-1 hover:scale-125"
          >
            <a href="tel:+60123877518 " class="md:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="40"
                height="40"
                class="fill-current"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
                />
              </svg>
            </a>
            
          </div>

          <div class="hidden md:text-base md:text-gray-600 md:font-medium md:text-right md:block">
            <p class="md:text-xl text-brown-2">Call Us</p>
            <p><a href="tel:+60123877518" class="transition duration-200 hover:text-brown-2">012-387 7518</a> |  <a href="tel:+60163281463" class="transition duration-200 hover:text-brown-2">016-328 1463</a></p>
          </div>
        </div>
      </div>
    </div>
    <!-- main banner -->
    <MainBanner />

    <!-- product -->
    <div class="py-10">
      <div class="container">
        <div class="py-4 mx-auto text-center">
          <h1 class="text-3xl font-bold text-center text-brown-1">
            Our Products
          </h1>
          <div class="w-10 pt-4 mx-auto border-b-2 border-brown-1"></div>
          <h3 class="pt-6 pb-3 text-xl font-semibold text-center text-brown-3">
            Vinyl Flooring
          </h3>
          <div class="flex flex-wrap">
            <div
              v-for="(item, i) in vinyl"
              :key="i"
              class="relative w-full md:w-1/2 lg:w-1/4 text-overlay"
            >
              <img :src="item.image" :alt="item.alt" class="w-full" />
            </div>
          </div>

          <div class="pt-4">
            <div class="text-gray-600 md:flex md:flex-wrap">
              <div v-for="list in sub_list" :key="list" class="pb-1 md:p-1 md:w-1/2">
                <p
                  class="py-2 transition duration-200 bg-gray-100 hover:bg-brown-4"
                >
                  {{ list }}
                </p>
              </div>
            </div>
            <div class="flex flex-wrap pt-4 lg:max-w-3xl lg:mx-auto">
              <div
                v-for="(item, i) in icon_vinyl"
                :key="i"
                class="relative w-1/2 p-3 text-center md:w-1/4"
              >
                <div class="p-6 border rounded-full border-brown-3">
                  <img :src="item.image" :alt="item.alt" class="mx-auto" />
                </div>
                <h1
                  class="h-12 pt-2 text-sm font-semibold text-gray-700 md:text-base"
                >
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div class="py-4 mx-auto text-center">
          <h3 class="pt-6 pb-3 text-xl font-semibold text-center text-brown-3">
            Stone Plastic Composite (SPC) Flooring
          </h3>
          <div class="flex flex-wrap">
            <div
              v-for="(item, i) in spc"
              :key="i"
              class="relative w-full md:w-1/2 lg:w-1/4 text-overlay"
            >
              <img :src="item.image" :alt="item.alt" class="w-full" />
            </div>
          </div>

          <div class="pt-4">
            <div class="flex flex-wrap pt-4 lg:max-w-3xl lg:mx-auto">
              <div
                v-for="(item, i) in icon_spc"
                :key="i"
                class="relative w-1/2 p-3 text-center md:w-1/4"
              >
                <div class="p-6 border rounded-full border-brown-3">
                  <img :src="item.image" :alt="item.alt" class="mx-auto" />
                </div>
                <h1
                  class="h-12 pt-2 text-sm font-semibold text-gray-700 md:text-base"
                >
                  {{ item.alt }}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us  -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1 class="text-3xl font-bold text-center text-white">Why Choose Us</h1>
        <div class="w-10 pt-4 mx-auto border-b-2 border-white"></div>

        <div class="pt-8 md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div
            v-for="(item, i) in choose_us"
            :key="i"
            class="w-3/4 pb-3 mx-auto md:mx-0 md:px-1 md:w-1/3"
          >
            <div class="p-5 border-2 border-brown-3">
              <img
                :src="item.image + '.png'"
                :alt="item.alt"
                class="w-24 mx-auto"
              />
              <div class="pt-3 text-center text-white">
                <p class="text-sm lg:text-base">{{ item.alt }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- testimonial -->
    <div class="py-10 bg-brown-1">
      <div class="container">
        <h1 class="text-3xl font-bold text-center text-white">
          Our Testimonials
        </h1>
        <div class="w-10 pt-4 mx-auto border-b-2 border-white"></div>

        <div class="pt-8">
          <carousel
            :autoplay="true"
            :loop="true"
            :responsive="{
              0: { items: 1, nav: false, dots: true },
              768: { items: 2, nav: false, dots: true, },
              1024: { items: 3, nav: false, dots: true, },
            }"
          >
            <div
              v-for="(item, i) in review"
              :key="i"
              class="mx-2 border rounded border-brown-4"
            >
              <div class="px-4 py-8 h-60">
                <p class="text-sm font-light leading-tight text-white">
                  {{ item.p }}
                </p>
              </div>
              <div
                class="flex items-center justify-between p-5 border-t border-brown-4"
              >
                <div>
                  <h2 class="text-sm font-medium leading-tight text-white">
                    {{ item.h2 }}
                  </h2>
                  <div class="text-yellow-300">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <div>
          <h1 class="text-3xl font-bold text-center text-brown-1">About Us</h1>
          <div class="w-10 pt-4 mx-auto border-b-2 border-brown-1"></div>
          <div class="pt-8 space-y-3 text-sm text-center text-gray-600">
            <p>
              <span class="font-bold">Basis Supreme Sdn Bhd</span> is a vinyl
              flooring supplier company, located in Bandar Menjalara Kuala
              Lumpur, Malaysia. We offer flooring services to customers both in
              the residence and commercial sectors. We provide supply and
              install for all types of vinyl floor, including <span class="font-bold">Loose Lay Vinyl,
              Dry Back Vinyl & SPC Vinyl and etc. </span>
            </p>
            <p>
              Our well train staffs are come with superb workmanship promise to
              provide you the best finishing flooring. We provide supply and
              install for vinyl flooring for residence, commercial include
              shopping mall, showroom and developer project.
            </p>
            <p>
              Our customer stratification is our main objective, we promise to
              provide the best workmanship and delivery on time for our project
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-enquiry.jpg')"
    >
      <div class="container lg:flex lg:items-center">
        <div class="lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-black bg-opacity-60 rounded-xl">
            <h1
              class="text-3xl font-bold leading-tight text-center text-white capitalize lg:text-4xl"
            >
              Send Us An Enquiry
            </h1>
            <EnquiryForm class="pt-8" />
          </div>
        </div>
        <div class="pt-6 lg:w-1/2">
          <img
            src="/images/main-logo-white.png"
            alt=""
            class="mx-auto w-72 md:w-80"
          />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <h1 class="pb-2 text-lg font-bold text-green-1">
                Basis Supreme Sdn Bhd
              </h1>
              <h1 class="text-lg font-medium leading-tight md:text-xl">
                A-2-33, Plaza Medan Putra, Jalan Medan Putra 6, Bandar
                Manjalara, 52200 K.L
              </h1>
              <div class="pt-4 font-medium">
                <p>
                  Call Us At :
                  <a
                    href="tel:+60123877518"
                    class="transition duration-200 ease-in-out transform hover:text-brown-3 hover:scale-110"
                  >
                    012-387 7518</a
                  >
                  |
                  <a
                    href="tel:+60163281463"
                    class="transition duration-200 ease-in-out transform hover:text-brown-3 hover:scale-110"
                  >
                    016-328 1463</a
                  >
                </p>
              </div>
              <div class="font-medium">
                <p>
                  WhatsApp :
                  <a
                    href="https://wa.me/60123877518"
                    class="transition duration-200 ease-in-out transform hover:text-brown-3 hover:scale-110"
                  >
                    012-387 7518</a
                  >
                  |
                  <a
                    href="https://wa.me/60163281463"
                    class="transition duration-200 ease-in-out transform hover:text-brown-3 hover:scale-110"
                  >
                    016-328 1463</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- location -->
    <div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15934.514066538928!2d101.624015!3d3.191947!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc48a9d55c9d39%3A0x3527579b0056ff7a!2sBasis%20Supreme%20Sdn.%20Bhd.!5e0!3m2!1sen!2smy!4v1667963993811!5m2!1sen!2smy"
        width="100%"
        height="200"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>

    <div class="py-3 bg-brown-1">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import WaBtn from "@/components/WaBtn.vue";
import carousel from "vue-owl-carousel";
import EnquiryForm from "@/components/EnquiryForm.vue";
import MainBanner from "@/components/MainBanner.vue"

export default {
  name: "Home",
  components: {
    WaBtn,
    carousel,
    EnquiryForm,
    MainBanner
  },
  data() {
    return {
      vinyl: [
        {
          image: "/images/vinyl-1.jpg",
          alt: "",
        },
        { image: "/images/vinyl-2.jpg", alt: "" },
        { image: "/images/vinyl-3.jpg", alt: "" },
        {
          image: "/images/vinyl-4.jpg",
          alt: "",
        },
      ],
      sub_list: [
        "5mm non-slip backing loose lay",
        "3mm dry back",
        "3mm dry back with glass fibre",
        "SPC",
      ],
      icon_vinyl: [
        {
          image: "/images/icon-vinyl-1.png",
          alt: "Anti Termite",
        },
        {
          image: "/images/icon-vinyl-2.png",
          alt: "Slip Resistant",
        },
        {
          image: "/images/icon-vinyl-3.png",
          alt: "Impact Resistant",
        },
        {
          image: "/images/icon-vinyl-4.png",
          alt: "Temperature Resistant​​​​​​​",
        },
      ],

      spc: [
        {
          image: "/images/spc-1.jpg",
          alt: "",
        },
        { image: "/images/spc-2.jpg", alt: "" },
        { image: "/images/spc-3.jpg", alt: "" },
        {
          image: "/images/spc-4.jpg",
          alt: "",
        },
      ],
      icon_spc: [
        {
          image: "/images/icon-vinyl-1.png",
          alt: "Anti Termite",
        },
        {
          image: "/images/icon-vinyl-2.png",
          alt: "Slip Resistant",
        },
        {
          image: "/images/icon-vinyl-5.png",
          alt: "Waterproof",
        },
        {
          image: "/images/icon-vinyl-6.png",
          alt: "Soft And Quiet Underfoot​​​​​​​",
        },
      ],
      choose_us: [
        { image: "/images/choose-1", alt: "Premium Quality Products" },
        { image: "/images/choose-2", alt: "Free Consultation & Quote" },
        { image: "/images/choose-3", alt: "Reasonable Price" },
      ],
      review: [
        {
          h2: "Evon Low (shop owner)",
          p: "Superb finishing and delivery on time, very happy to work with basis supreme.",
        },
        {
          h2: "Muhdabduh Abdullah (home owner)",
          p: "The loose lay vinyl really wort for the price, it look awesome.",
        },
        {
          h2: "Eric Wong (developer staff)",
          p: "Basis supreme finish the job ahead schedule make my project proceed very smooth, looking forward to cooperate again in next project.",
        },
        {
          h2: "Daren Choong (Business owner)",
          p: "I install the 3mm vinyl for my shop, the floor look really nice and value for the money. Thanks Basis Supreme",
        },
      ],
    };
  },
};
</script>
